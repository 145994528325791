import React from 'react'
import { graphql } from 'gatsby'
import { Accordion, Card } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'

import cad1 from '../../../assets/images/cad-designs1.png'
import cad2 from '../../../assets/images/cad-designs2.png'
import cad3 from '../../../assets/images/cad-designs3.png'
import cad4 from '../../../assets/images/cad-designs4.png'
import cad5 from '../../../assets/images/cad-designs5.png'
import cad6 from '../../../assets/images/cad-designs6.png'
import cad7 from '../../../assets/images/cad-designs7.png'
import cad8 from '../../../assets/images/cad-designs8.png'
import cad9 from '../../../assets/images/cad-designs9.png'
import cad10 from '../../../assets/images/cad-designs10.png'
import cad11 from '../../../assets/images/cad-designs11.png'
import cad12 from '../../../assets/images/cad-designs12.png'
import cad13 from '../../../assets/images/cad-designs13.png'
import cad14 from '../../../assets/images/cad-designs14.png'
import cad15 from '../../../assets/images/cad-designs15.png'
import cad16 from '../../../assets/images/cad-designs16.png'
import cad17 from '../../../assets/images/cad-designs17.png'
import cad18 from '../../../assets/images/cad-designs18.png'
import cad19 from '../../../assets/images/cad-designs19.png'
import cad20 from '../../../assets/images/cad-designs20.png'
import cad21 from '../../../assets/images/cad-designs21.png'
import cad22 from '../../../assets/images/cad-designs22.png'
import cad23 from '../../../assets/images/cad-designs23.png'
import cad24 from '../../../assets/images/cad-designs24.png'
import cad25 from '../../../assets/images/cad-designs25.png'
import cad26 from '../../../assets/images/cad-designs26.png'
import cad27 from '../../../assets/images/cad-designs27.png'
import cad28 from '../../../assets/images/cad-designs28.png'
import cad29 from '../../../assets/images/cad-designs29.png'
class CadDesigns extends React.Component {
	render() {
		const siteTitle = 'CAD Designs - J&J Automatic Gate Service & Design'
		const siteDescription =
			'J&J Gate Service & Design makes your dream security gate a reality with FabCAD software that makes the process fast, easy, and simple.'

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />

				<div className="container-fluid text-center headline gates-headline">
					<div className="container">
						<h1 className="text-white m-0">CAD Designs</h1>
						<hr />
					</div>
				</div>

				<div className="container d-padding-t d-padding-b access-sys">
					<div className="row">
						<div className="col-md-12">
							<h4>
								If You Can Dream It, We Can Do It – Commercial & Residential
								Security Gate Design in Dallas/Fort Worth
							</h4>
							<p>
								What’s the ideal vision you have in mind for your security
								gate…whether you’re a homeowner or business owner?
							</p>
							<p>
								Do you think of a beautiful wrought iron gate as you see on
								gorgeous homes you see on your favorite TV shows?
							</p>
							<p>
								…Or do you think of a simpler, more down-to-earth gate that
								restricts access to your property?
							</p>
							<p>
								When you hire a service to design and build your gate, you may
								feel quite anxious and afraid. Will you end up with the gate you
								envision in your mind?
							</p>
							<p>Or, will the gate you get fall far short?</p>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<h4>
								J&J Automatic Gate Service & Design Makes Your Dream Security
								Gate A Perfect Reality
							</h4>
							<p>
								We recently purchased FabCAD. It’s design software created
								especially for fencing and ornamental metal companies just like
								us.
							</p>
							<p>
								It allows us to create a visual design that shows you exactly
								how your gate will look when constructed and installed. Then,
								you get a copy of this design by email, and you can tweak every
								little aspect until you’re perfectly happy with what the
								finished product will look like.
							</p>
							<p>
								This means you have far less stress throughout the entire
								process. And it puts an end to hiccups and misunderstandings.
							</p>
							<p>
								You get peace of mind, knowing that your investment in your
								security gate will get you exactly what you want.
							</p>
							<p>
								Best of all, you don’t have to make any additional trips to our
								office. And it doesn’t cost you anything extra.
							</p>
							<p>
								Add to this our reputation for excellent service (feel free to
								research us on the web), and you have the recipe for the best
								security gate design and installation service in all of Tarrant
								County!
							</p>
							<p>
								So, begin with a free quote by calling J&J Automatic Gate
								Service & Design at 817.466.2794.
							</p>
							<p>
								You won’t experience any obnoxious high sales pressure. Simply
								chat with our professionals, discuss what you want, and learn
								exactly how our process makes your dream gate reality.
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<h4>
								Questions Dallas Businesses and Homeowners Frequently Ask About
								Security Gate Design
							</h4>
							<Accordion defaultActiveKey="0">
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey="1">
										<h5 className="my-0">
											What’s so special about FabCAD, your gate design software?
										</h5>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="1">
										<Card.Body>
											<p>
												It makes designing your commercial or residential gates
												a cinch. The other option would be going with more
												complex software, like AutoCAD.
											</p>
											<p>
												FabCAD, however, cuts the time required to create your
												design by at least 10 times, and it doesn’t require
												extensive training. So, that means you get your design
												much faster, and you don’t have to pay anything
												additional for that part of the gate design process.{' '}
											</p>
											<p>
												Plus, their customer service absolutely rocks. So it’s
												quite an amazing tool to have that almost guarantees you
												end up with a gate that puts a smile all over your face.{' '}
											</p>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey="2">
										<h5 className="my-0">
											I’m not entirely sure what I want from my gate. Can you
											give me ideas?
										</h5>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="2">
										<Card.Body>
											<p>
												Yes. We’ve been in business in the Dallas/Fort Worth
												Metroplex for more than a decade. And we’ve created
												every type of design you can imagine:
												<ul className="ml-3">
													<li>
														<i className="fas fa-dungeon mr-2" />
														Wooden Gates
													</li>
													<li>
														<i className="fas fa-dungeon mr-2" />
														Wrought Iron Gates
													</li>
													<li>
														<i className="fas fa-dungeon mr-2" />
														ALuminum Gates
													</li>
													<li>
														<i className="fas fa-dungeon mr-2" />
														Chain Link Gates
													</li>
													<li>
														<i className="fas fa-dungeon mr-2" />
														Pedestrian Gates
													</li>
													<li>
														<i className="fas fa-dungeon mr-2" />
														Driveway Gates
													</li>
													<li>
														<i className="fas fa-dungeon mr-2" />
														Estate Gates
													</li>
													<li>
														<i className="fas fa-dungeon mr-2" />
														Automatic Gates
													</li>
													<li>
														<i className="fas fa-dungeon mr-2" />
														Solar-Powered Gates
													</li>
													<li>
														<i className="fas fa-dungeon mr-2" />
														Swing Gates
													</li>
												</ul>
											</p>
											<p>
												Check out our <strong>gallery below for designs</strong>{' '}
												or ask for more images to stimulate your imagination.
												Or, just browse around the internet and find an image of
												something you like.{' '}
											</p>
											<p>We can make whatever you want reality.</p>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey="3">
										<h5 className="my-0">
											Can I operate my gate if the power goes out?
										</h5>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="3">
										<Card.Body>
											<p>
												Yes. We can help you install a solution for that. You
												can install a backup battery unit. This unit will either
												keep your gates open until you get power again – or
												close and open your gates until the battery runs out of
												juice.
											</p>
											<p>
												<strong>
													You can also install a mechanism so you can manually
													open your gates yourself. So, you’ll never need to
													worry about opening your gates if you lose power.
												</strong>
											</p>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey="4">
										<h5 className="my-0">
											Can I protect my equipment from lightning damage?
										</h5>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="4">
										<Card.Body>
											<p>
												Yes. You can install surge protection on any security
												gate you own. While this offers additional protection
												from power surges, it’s impossible to guarantee
												protection from lightning 100%.
											</p>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey="5">
										<h5 className="my-0">
											What kind of gate should I install?
										</h5>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="5">
										<Card.Body>
											<p>
												We can explore that with you and help you discover what
												you really want. Be sure to install whatever makes you
												happy and meets your needs.
											</p>
											<p>
												You may want a driveway gate mostly for its beauty, or
												you may want to keep costs down and focus on security.
												Or maybe you want a gorgeous gate that’s also secure.
											</p>
											<p>
												Try to identify what’s most important to you in your
												home or business security gate. If you can’t, we’ll help
												you go through options so you can find the gate that
												makes you happy.
											</p>
											<p>
												We’ve installed every type of gate imaginable and can
												work with any budget, ranging from minimal to massive.
												So you have no need to worry about our ability to make
												your vision a reality.
											</p>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey="6">
										<h5 className="my-0">
											How does maintenance for security gates work?
										</h5>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="6">
										<Card.Body>
											<p>
												Believe it or not, most maintenance is relatively simple
												and can be learned, even if you don’t fancy yourself a
												handyperson. However, our professionals can take care of
												any ongoing maintenance needed for you if you don’t want
												to do it yourself.
											</p>
											<p>
												Be aware that various types of gates require different
												levels of maintenance. Wooden gates, for example,
												require the most ongoing maintenance. Wooden gates with
												wooden frames can require repair as they sag over time.
												You may need to pressure wash or even repaint your
												wooden gate.
											</p>
											<p>
												Wrought iron requires a fair amount of care too. You may
												need to clean off the rust. Aluminum gates, while they
												aren’t pretty (but do work great for security), require
												practically no maintenance at all.
											</p>
											<p>
												When we design your security gate, make sure you bring
												up the topic of maintenance. Specific design techniques
												can be used to minimize maintenance.
											</p>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey="7">
										<h5 className="my-0">
											What’s the difference between sliding and swinging gates?
										</h5>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="7">
										<Card.Body>
											<p>
												Besides the obvious functional difference, sliding gates
												offer greater access control and reduce the frequency of
												forced entry.
											</p>
											<p>
												Swinging gates require less maintenance and are more for
												looks.
											</p>
											<p>
												You’ll have to decide which is more important to you.
											</p>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey="8">
										<h5 className="my-0">
											How much does residential or business security gate
											installation cost?
										</h5>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="8">
										<Card.Body>
											<p>
												It really depends on what you want. For the barebones
												security gate, you’re looking at a few thousand bucks.
											</p>
											<p>
												If need to go all out and impress your guests or
												customers, you can easily spend $50,000 or more. It’s
												really up to you and how much customization you want.
											</p>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey="9">
										<h5 className="my-0">
											What kinds of entry systems are available?
										</h5>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="9">
										<Card.Body>
											<p>
												There are three kinds: key fob, phone, and underground
												sensor.
											</p>
											<p>
												A phone entry system is the most common. Each user of
												the system gets a unique code they must input on a
												keypad found near your gate. Visitors can also call your
												computer, smartphone, or landline phone from the station
												with the keypad and request access. You can also assign
												a special unique access code for repairmen.
											</p>
											<p>
												The key fob system works just as you’d expect: you
												simply push a button on your key fob to gain access.
											</p>
											<p>
												Underground sensors detect whether a car is just above,
												like a traffic light. However, they can’t uniquely
												identify your vehicle only, so you get the least
												security with this option.
											</p>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey="10">
										<h5 className="my-0">
											What safety features should my security gate have?
										</h5>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="10">
										<Card.Body>
											<p>
												You should have at least two: a reverse function and a
												reverse function triggered by a photo beam.
											</p>
											<p>
												The standard reverse function safety feature simply
												reverses the direction of your gate when it bumps into
												an object and can’t move further without exerting a
												certain amount of pressure.
											</p>
											<p>
												The photo beam triggered reverse function sends a beam
												of infrared light low to the ground. If this beam gets
												interrupted, then the gate stops and reverses direction.
											</p>
											<p>
												There are other safety features available too. But those
												are the standard.
											</p>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey="11">
										<h5 className="my-0">How long will my gate last?</h5>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="11">
										<Card.Body>
											<p>
												It really depends on the material, maintenance, and
												extent of use. Aluminum gates can last for decades
												before requiring complete replacement because they don’t
												rust.
											</p>
											<p>
												Wood eventually sags and rots. Steel and iron rust.
												However, these driveway gates are much more beautiful to
												look at.
											</p>
											<p>
												If you do a good job maintaining your gates along the
												way, you can squeeze a few more years of life out of
												them.
											</p>
											<p>
												And, gates used less will require less maintenance and
												likely have a longer life. For example, if you’re a
												homeowner and only open your gate a couple of times per
												day, compared to a business that lets dozens of
												customers in and out all day long.
											</p>
											<p>
												In general, however, you should expect to get at least a
												decade out of your gates before needing extensive repair
												or replacement.
											</p>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey="12">
										<h5 className="my-0">
											How often should I have my gate serviced?
										</h5>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="12">
										<Card.Body>
											<p>
												In general, about every 6 months. However, this depends
												somewhat on the amount of use. If you have a high use
												gate for commercial purposes, for example, you may even
												push that frequency to quarterly.
											</p>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							</Accordion>
						</div>
					</div>
					<div className="row text-center">
						<div className="col-md-12">
							<h4>
								Get Your Free Commercial or Residential Security Gate Quote
								Today
							</h4>
							<p>
								J&J Automatic Gate Service & Design makes your gate a reality…no
								matter what vision you have in your mind. And thanks to our new
								FabCAD software it’s easy and painless for you to show us
								exactly what you want.
							</p>
							<p>So, call us today at 817.466.2794 to get your free quote!</p>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<LazyLoadImage
								src={cad1}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
						<div className="col-md-4">
							<LazyLoadImage
								src={cad2}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
						<div className="col-md-4">
							<LazyLoadImage
								src={cad3}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<LazyLoadImage
								src={cad4}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
						<div className="col-md-4">
							<LazyLoadImage
								src={cad5}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
						<div className="col-md-4">
							<LazyLoadImage
								src={cad6}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<LazyLoadImage
								src={cad7}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
						<div className="col-md-4">
							<LazyLoadImage
								src={cad8}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
						<div className="col-md-4">
							<LazyLoadImage
								src={cad9}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<LazyLoadImage
								src={cad10}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
						<div className="col-md-4">
							<LazyLoadImage
								src={cad11}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
						<div className="col-md-4">
							<LazyLoadImage
								src={cad12}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<LazyLoadImage
								src={cad13}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
						<div className="col-md-4">
							<LazyLoadImage
								src={cad14}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
						<div className="col-md-4">
							<LazyLoadImage
								src={cad15}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<LazyLoadImage
								src={cad16}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
						<div className="col-md-4">
							<LazyLoadImage
								src={cad17}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
						<div className="col-md-4">
							<LazyLoadImage
								src={cad18}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<LazyLoadImage
								src={cad19}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
						<div className="col-md-4">
							<LazyLoadImage
								src={cad20}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
						<div className="col-md-4">
							<LazyLoadImage
								src={cad21}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<LazyLoadImage
								src={cad22}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
						<div className="col-md-4">
							<LazyLoadImage
								src={cad23}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
						<div className="col-md-4">
							<LazyLoadImage
								src={cad24}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<LazyLoadImage
								src={cad25}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
						<div className="col-md-4">
							<LazyLoadImage
								src={cad26}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
						<div className="col-md-4">
							<LazyLoadImage
								src={cad27}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<LazyLoadImage
								src={cad28}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
						<div className="col-md-4">
							<LazyLoadImage
								src={cad29}
								className="img-fluid"
								alt="CAD Design Gate"
							/>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default CadDesigns

export const CadDesignsPageQuery = graphql`
	query CadDesignsPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
